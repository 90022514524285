<template>
  <swiper
    @slideChange="slideChange"
    :parallax="true"
    :loop="true"
    :autoplay="{
      delay: 2000,
    }"
    class="productWechatSwiper"
  >
    <swiper-slide>
      <img src="../assets/imgs/productWechatSwiper/wechat_swiper_1.png" />
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/imgs/productWechatSwiper/wechat_swiper_2.png" />
    </swiper-slide>
    <swiper-slide>
      <img src="../assets/imgs/productWechatSwiper/wechat_swiper_3.png" />
    </swiper-slide>
  </swiper>
</template>
<script>
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
//这里导入对应样式
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
//导入后在SwiperCore.use([])里注册
SwiperCore.use([Pagination, Navigation, Autoplay]);
export default {
  name: "ProductWechatSwiper",
  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    const slideChange = (swiper) => {
      // console.log(swiper.activeIndex);
      // console.log(arguments);
    };
    return { slideChange };
  },

  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
.productWechatSwiper {
  width: 100%;
  margin-top: 81px;
  padding-bottom: 70px;
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
  .swiper-slide {
    padding: 0;
  }
}

@media screen and (max-width: 700px) {
}
@media screen and (max-width: 600px) {
  .productWechatSwiper {
    padding-bottom: 0px;
  }
}
</style>

<style lang="css">
@import "./tabCont/style.css";
</style>
