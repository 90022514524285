<template>
  <div class="productWechat">
    <div class="topImg">
      <img
        class="productImg hiden"
        src="../assets/imgs/productWechat/top_title_img.png"
      />
      <img
        class="productImg show"
        src="../assets/imgs/productWechat/phone_title_img.png"
        alt=""
      />
    </div>
    <div class="productsIntroduction">
      <h3>畅名片小程序</h3>
      <dl>
        <dd class="hiden">
          <img src="../assets/imgs/productWechat/logo.png" alt="" />
        </dd>
        <dt>
          <p>
            “畅名片”作为一款面向商务人士使用的智能数字名片轻应用，
            它可以让你在各种场合下为对方速递上你精心自定义的名片，
            更快的建立与合作方等各种人脉的联系，
            名片中丰富的信息可以让别人更快的了解你，了解公司和业务，
            同时也可提高自身和企业形象。
            <br class="hiden" />
          </p>
        </dt>
      </dl>
    </div>
    <!-- <ProductTab class="hiden" /> -->
    <div class="cont">
      <div class="Backlog row calendar tabCont">
        <div class="img col-3">
          <img src="../assets/imgs/productWechat/img1.png" alt="" />
        </div>
        <div class="text col-6">
          <h2>灵活的名片样式模板，快速搭建名片</h2>
          <p>
            “畅名片”全方位提供个人信息以及公司、业务信息的图文介绍。 同时更无需担心名片样式单一，“畅名片” 可以根据个人偏好设置不同的名片样式。
          </p>
        </div>
      </div>
      <div class="contacts row tabCont">
        <div class="text col-5 hiden">
          <h2>收发名片，快速搞定</h2>
          <p>
            收发名片力求高效，所见即所发，打开“畅名片”即可一键分享自己的名片。
          </p>
          <p>
            支持“小程序卡片分享”和“海报分享”，并支持设置名片分享文案。打开分享链接一键放入名片夹，开启人脉维护的第一步。
          </p>
        </div>
        <div class="img col-3">
          <!-- <img src="../assets/imgs/productWechat/img2.png" alt="" /> -->
          <ProductWechatSwiper />
        </div>
        <div class="text col-5 show">
          <h2>收发名片，快速搞定</h2>
          <p>
            收发名片力求高效，所见即所发，打开“畅名片”即可一键分享自己的名片。
            <br class="hiden" />
            <br class="hiden" />
            支持“小程序卡片分享”和“海报分享”，并支持设置名片分享文案。打开分享链接一键放入名片夹，开启人脉维护的第一步。
          </p>
        </div>
      </div>
      <div class="calendar row tabCont">
        <div class="img col-3">
          <img src="../assets/imgs/productWechat/img3.png" alt="" />
        </div>
        <div class="text col-6">
          <h2>操作便捷，简单好用</h2>
          <p>
            名片夹中支持“手动录入”和“拍名片”，拍名片自动解析，无需逐个录入，
            效率倍增。通过姓名、手机号和公司名称快捷搜索名片，告别逐个翻看纸质名片。
          </p>
        </div>
      </div>
    </div>
    <!-- 下载 -->
    <div class="download">
      <img src="../assets/imgs/sub_text_icon.png" class="downloadImg hiden" />
      <div class="cont">
        <h2>
          商务人士都在用的智能数字名片
        </h2>
        <p>你有没有迫不及待想体验“畅名片”了呢？那就快来行动吧！</p>
      </div>
    </div>
    <div class="downloadApp">
      <div class="download">
        <div class="downloadList">
          <dl class="ios">
            <dt>
              <img src="../assets/imgs/productWechat/open_wechat.png" alt="" />
            </dt>
            <dd>畅名片</dd>
            <dd>
              <div>
                <span class="hiden">
                  扫码体验
                </span>
                <span class="show">
                  长按识别小程序码
                </span>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import ProductTab from "../components/ProductTab.vue";
import Download from "../components/Download.vue";
import offlineIcon from "../assets/imgs/offline_icon.png";
import multideviceIcon from "../assets/imgs/multidevice_icon.png";
import calendarIcon from "../assets/imgs/calendar_icon.png";
import commissionIcon from "../assets/imgs/commission_icon.png";
import incidentIcon from "../assets/imgs/incident_icon.png";
import summaryIcon from "../assets/imgs/summary_icon.png";
import templateIcon from "../assets/imgs/template_icon.png";
import ProductWechatSwiper from "../components/ProductWechatSwiper.vue";
export default {
  name: "ProductWechat",
  components: { ProductTab, Download, ProductWechatSwiper },
  setup() {
    const router = useRouter();
    const productCont = [
      {
        icon: offlineIcon,
        title: "离线操作",
        text: "没有网络也能使用",
      },
      {
        icon: multideviceIcon,
        title: "多端登录",
        text: "支持手机/平板同时登录",
      },
      {
        icon: templateIcon,
        title: "人脉模板",
        text: "强大的人脉模板管理",
      },
      {
        icon: incidentIcon,
        title: "互动事件",
        text: "查看和人脉的过往事件",
      },
      {
        icon: summaryIcon,
        title: "事件关联纪要",
        text: "事件内支持添加纪要",
      },
      {
        icon: commissionIcon,
        title: "纪要关联待办",
        text: "纪要内支持添加待办",
      },
      {
        icon: calendarIcon,
        title: "自定义日历设置",
        text: "自定义起始日和起始时间点",
      },
    ];
    const toDownload = () => {
      router.push({ name: "DownloadApp" });
    };
    return {
      toDownload,
      productCont,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<!-- <style lang="css">
  @import url('../components/tabCont/style.css');  
</style> -->
<style lang="scss" scoped>
.productWechat {
  .topImg {
    position: relative;
    height: 543px;
    overflow: hidden;
    .topImgMask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #fff, $alpha: 0.1);
      box-shadow: 0.25px 45px rgba($color: #000000, $alpha: 0.1);
      backdrop-filter: blur(2px);
      z-index: 2;
    }
    .productImg {
      width: 100%;
    }
    .text {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 3;
      h1 {
        font-size: 86px;
        color: #fff;
        line-height: 2.18;
        font-weight: 500;
        letter-spacing: 10px;
      }
    }
  }
}
.downloadApp {
  background-color: #f9faff;
}

.productCont {
  background-color: #f9faff;
  padding-top: 126px;
  padding-bottom: 135px;
  h2 {
    color: #192646;
    font-size: 57px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 100px;
  }
  .contList {
    text-align: center;
    dl {
      display: inline-flex;
      vertical-align: top;
      min-width: 388px;
      padding: 0 5px;
      height: 383px;
      background-color: #fff;
      border-radius: 21px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      &:not(:first-child) {
        margin-left: 30px;
      }
      img {
        width: 149px;
      }
      dd {
        p {
          white-space: nowrap;
        }
        p:first-child {
          color: #37474f;
          font-size: 34px;
        }
        p:last-child {
          color: rgba(55, 71, 79, 0.72);
          font-size: 23px;
        }
      }
    }
  }
}
.productWechat > .download {
  position: relative;
  background-color: #f7fbff;
  padding-top: 110px;

  .downloadImg {
    position: absolute;
    top: 67px;
    left: 132px;
    width: 236px;
  }
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 59px;
      color: #192646;
      font-weight: 500;
    }
    p {
      color: #8c919c;
      font-size: 25px;
      text-align: center;
      margin-top: 37px;
      margin-bottom: 70px;
    }
    .downloadBtn {
      width: 169px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      color: #fff;
      box-shadow: 0px 11px 15px 1px rgba(215, 222, 249, 1);
      border-radius: 34px;
      font-size: 16px;
      cursor: pointer;
      background: #5a76e4;
    }
  }
}
.subText {
  text-align: center;
  background-color: #fff;
  position: relative;
  padding: 99px 0;
  h2 {
    color: #192646;
    font-size: 54px;
    margin-bottom: 70px;
  }
  .subTextIcon {
    position: absolute;
    width: 110px;
    left: 103px;
    bottom: 102px;
  }
  .subTextIcon2 {
    position: absolute;
    width: 42px;
    height: 28px;
    top: 0px;
    left: 0px;
  }
  .subTextIcon3 {
    position: absolute;
    width: 42px;
    height: 28px;
    right: 0px;
    bottom: 0px;
  }
  .subBottomText {
    display: inline-block;
    position: relative;
    padding: 30px 45px;
    margin: 0 50px;
    p {
      color: #192646;
      font-size: 23px;
      line-height: 2.1;
    }
  }
}
.productsIntroduction {
  color: #192646;
  padding-bottom: 100px;
  h3 {
    font-size: 47px;
    text-align: center;
    line-height: 72px;
    margin-bottom: 42px;
    padding-top: 52px;
  }
  dl {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    dt {
      text-align: justify;
      width: 1100px;
      margin-left: 30px;
      font-size: 24px;
      font-weight: 500;
      margin-left: 70px;
      margin-right: 50px;
      line-height: 2.2;
    }
    dd:nth-of-type(1) {
      img {
        height: 141px;
      }
    }
    dd:nth-of-type(2) {
      img {
        height: 309px;
      }
    }
  }
}
.downloadApp .download {
  text-align: center;
  padding-bottom: 96px;
  .downloadList {
    position: relative;
  }
  h3 {
    text-align: center;
    font-size: 36px;
    font-weight: normal;
    color: #000;
    line-height: 107px;
    margin-block: 0;
    margin-bottom: 30px;
  }
  dl {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 384px;
    height: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 71px 0px rgba(233, 237, 244, 1);
    border-radius: 11px;
    margin-right: 45px;
    img {
      width: 188px;
    }
    dd:nth-of-type(1) {
      color: #000000;
      font-size: 36px;
      line-height: 107px;
    }
    dd:nth-of-type(2) div {
      height: 62px;
      border-radius: 5px;
      line-height: 62px;
      text-align: center;
      color: #5679f9;
      font-size: 25px;
    }
  }
}
@media screen and (max-width: 700px) {
  .download .cont .downloadBtn {
    width: 300px;
    height: 110px;
    line-height: 110px;
    border-radius: 55px;
    transform: scale(0.7);
  }
  .subText {
    .subTextIcon {
      width: 140px;
      bottom: 60px;
      left: 60px;
    }
  }
  .productsIntroduction {
    dl {
      margin-right: 100px;
      dt {
        width: 940px;
      }
    }
  }
}
.productWechat {
  & > .cont {
    padding-bottom: 254px;
  }
  .row.tabCont {
    .img {
      margin-left: 0px;
    }
    .text {
      margin-left: 120px;
    }
  }
  .contacts.row.tabCont .text {
    margin-left: 0px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 600px) {
  .subText {
    padding: 70px 0;
    h2 {
      margin-bottom: 40px;
      font-size: 49px;
    }
    .subBottomText {
      padding: 30px 0px;
      margin: 0 40px;
      .phtonText p {
        font-size: 33px;
        letter-spacing: 2px;
        line-height: 52px;
      }
    }
    .subTextIcon {
      width: 110px;
      top: 75px;
      right: 58px;
      bottom: auto;
      left: auto;
    }
    .subTextIcon3 {
      bottom: 23px;
      right: -5px;
    }
    .subTextIcon2 {
      left: 0px;
    }
  }
  .productCont {
    padding-top: 50px;
    padding-bottom: 70px;
    h2 {
      font-weight: 500;
      line-height: 74px;
      margin-bottom: 50px;
      font-size: 49px;
    }
    .contList {
      text-align: left;
      padding-left: 13.3px;
      dl {
        margin-left: 30px;
        text-align: center;
        min-width: 420px;
        box-shadow: 0px 0px 77px 0px rgba(0, 0, 0, 0.06);
        margin-top: 20px;
        img {
          width: 162px;
        }
        dd p:first-child {
          font-size: 38px;
          font-weight: 500;
        }
        dd p:last-child {
          font-size: 32px;
        }
      }
    }
  }
  .productWechat .download {
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 30px;
    .cont {
      position: relative;
      h2 {
        text-align: center;
        line-height: 85px;
        font-size: 49px;
      }
      p {
        font-size: 33px;
        width: 640px;
      }
      .downloadBtn {
        width: 352px;
        height: 141px;
        border-radius: 70px;
        box-shadow: 0px 13px 18px 2px rgba(215, 222, 249, 1);
        font-size: 36px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1.5px;
      }
    }
  }
  .showBlock.downloadBtn {
    display: flex !important;
  }
  .productsIntroduction {
    background-color: #f9faff;
    padding-bottom: 80px;
    img.show {
      width: 100%;
      height: auto;
      margin-top: -20px;
    }
    h3 {
      font-size: 62px;
    }
    dl {
      display: block;
      margin: 0;
      dt {
        width: 820px;
        margin: 0 auto;
        font-size: 30px;
        p {
          text-align: justify;
          font-weight: normal;
        }
      }
    }
  }
  .productWechat .topImg {
    .text {
      h1 {
        font-size: 65px;
        letter-spacing: 2px;
      }
    }
    .productImg {
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: 1;
    }
  }
  .downloadApp {
    margin-top: -100px;
    .download {
      dl {
        width: 671px;
        height: 470px;
        margin-right: 0;
        margin-bottom: 50px;
        img {
          width: 220px;
        }
        dd:nth-of-type(1) {
          line-height: 80px;
          margin-top: 10px;
        }
      }
    }
  }
  .row.tabCont {
    flex-direction: column;
    margin-top: 40px;
    h2 {
      font-weight: 500;
    }
    & > div {
      width: 100%;
    }
    .swiper-container ::v-deep img {
      height: 924px !important;
    }
    .img {
      margin-bottom: 60px;
      img {
        height: 924px;
      }
    }
    .text {
      margin-left: 0 !important;
      text-align: center;
      p {
        margin: 0 auto;
      }
    }
  }
  .contacts {
    background-color: #f9faff;
    padding-top: 30px;
    padding-bottom: 90px;
  }
  .productWechat {
    & > .cont {
      padding-bottom: 94px;
    }
  }
  .tabCont {
    p {
        margin-bottom: 30px !important;
        width: 830px;
    }
    h2 {
        margin-bottom: 60px;
    }
}
}
</style>
